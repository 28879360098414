console.log("Vite ⚡️ Rails");

window.addEventListener("load", () => {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Serviceworker registered: ", registration);

      // @ts-ignore
      var serviceWorker;
      if (registration.installing) {
        serviceWorker = registration.installing;
        console.log("Service worker installing.");
      } else if (registration.waiting) {
        serviceWorker = registration.waiting;
        console.log("Service worker installed & waiting.");
      } else if (registration.active) {
        serviceWorker = registration.active;
        console.log("Service worker active.");
      }
    });
});

import "../bg";
